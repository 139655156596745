import React from "react";
import { graphql, Link, PageProps } from "gatsby";

import Layout from "@/components/Layout";
import Plate from "@/components/Plate";
import Hero from "@/components/Hero";
import Halo from "@/components/Halo";

import { Text, Flex, Box, Container, NavLink } from "theme-ui";

import Departments from '../components/pager'

export interface HomeProps {
  data: any;
}


const Home: React.FC<HomeProps> = () => (
  <Layout>
    <Halo title="Calicut Centre for Surgery" />
    <Plate variant="styles.contentFrameNil">
      <Hero />
      <Container>
        <Box sx={{ py: [3, 3, 7], px: [5, 5, 0] }}>
          <Plate variant="styles.bodyFrame">
            <Text as="h1" variant="sectionTitle" sx={{ fontSize: 5, fontWeight: 100, color: 'primary', mb: 4 }}>
              About Calicut Centre for Surgery
            </Text>
            <Text as="p" variant="sectionBody" sx={{ lineHeight: 1.5, maxWidth: '50ch', fontSize: 3 }}>
              CCS is a speciality healthcare facility that performs multi-disciplinary surgeries in a safe and
              conducive environment. We offer a holistic treatment from pre-surgery preparation to postoperative care. Introducing an unprecedented treatment environment, administrative work
              is separated from the surgery paraphernalia allowing surgeons at the centre to solely focus
              on the surgery with the aid of trained staff working round the clock. Our efforts are
              directed at making our treatment process an economical and time-saving alternative for the
              patients. We make surgery both a patient and surgeon friendly experience to prioritize
              restoration of your health above everything else.
            </Text>
          </Plate>
        </Box>
        <Box sx={{ p: [2, 2, 2] }}>
          <Plate>
            <Text as="h1" variant="sectionTitle" sx={{ fontSize: 5, fontWeight: 100, color: 'primary', mb: 4 }}>
              Our Departments
            </Text>
            <Text as="p" variant="sectionBody" sx={{ lineHeight: 1.5, maxWidth: '50ch', fontSize: 2 }}>
              <Flex sx={{ flexWrap: 'wrap' }}>
                {Departments.map((dep: any) => (
                  <Box sx={{ p: 3, pl: 0, mb: 3, maxWidth: ['100%', 'auto', "50%"], minWidth: ['100%', 'auto', "50%"], borderBottom: 'solid 1px #eee' }}>
                    <Link to={ `/departments/${dep.path}`}>
                      <Text as="h3" variant="innerTitle" sx={{ mb: 1, fontWeight: 100 }}>{dep.name}</Text>
                    </Link>
                  </Box>
                ))}
              </Flex>
            </Text>
          </Plate>
        </Box>
      </Container>
    </Plate>
  </Layout>
);

export default Home;
