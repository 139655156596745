/** @jsx jsx */

import { Link } from "gatsby";
import { FC } from "react";
import {
  Box,
  jsx,
  Text,
  Flex,
  NavLink,
  useColorMode,
  Container,
} from "theme-ui";
import Plate from "./Plate";

export interface HeroProps {
  data?: {
    title?: string;
    description?: string;
  };
}

const Hero: FC<HeroProps> = () => {
  const [colorMode] = useColorMode();

  return (
    <Box
      variant="styles.plateHero"
      sx={{
        height: "90vh",
        backgroundSize: "cover",
        position: 'relative',
        backgroundImage:
          "url(https://media.istockphoto.com/photos/female-nurse-and-family-with-senior-patient-at-home-picture-id1178548547?k=6&m=1178548547&s=612x612&w=0&h=sdtvtNd9q8jO39xxB_ND6VlseBfw9EqHtvA3plenc44=)",
      }}
    >
      <Box sx={{ bg: '#0b16219e', position: 'absolute', left: 0, right: 0, top: 0, bottom: 0}}>
        <Container>
          <Box sx={{ maxWidth: `70ch`, pt: 8, pb: 8 }}>
            <Text
              as="h1"
              variant="hero"
              sx={{ fontSize: 7, color: "white", fontFamily: "heading", fontWeight: 100 }}
            >
              Speciality Healthcare Facility
            </Text>
            <Text
              as="h2"
              variant="hero2"
              sx={{ color: "grays.6", fontFamily: "heading" }}
            >
              <Text as="p">in the heart of Calicut</Text>
            </Text>
          </Box>
          <Flex sx={{}}>
            {/* <NavLink
              as={Link}
              variant="button"
              to="/contact"
              sx={{ bg: "#1CADC8", px: 5, py: 3 }}
            >
              Book Appointment
            </NavLink> */}
            {/* <NavLink as={Link} variant="button" to="/case-studies">
            Check Works
          </NavLink> */}
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};

export default Hero;
